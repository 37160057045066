import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const BoardsData = async () => {
  return {
    "To Do": [
      {
        id: "zb7zxtjctd",
        name: "Unable to upload file",
        description:
          "Scenester hashtag sustainable art party 3 wolf moon live-edge portland offal master cleanse disrupt viral palo santo tacos. Offal sriracha you probably havent heard of them vaporware glossier.",
        cover: "/img/others/img-13.jpg",
        members: [
          {
            id: "1",
            name: "Carolyn Perkins",
            email: "eileen_h@hotmail.com",
            img: "/img/avatars/thumb-1.jpg"
          },
          {
            id: "2",
            name: "Terrance Moreno",
            email: "",
            img: "/img/avatars/thumb-2.jpg"
          },
          {
            id: "3",
            name: "Ron Vargas",
            email: "ronnie_vergas@infotech.io",
            img: "/img/avatars/thumb-3.jpg"
          }
        ],
        labels: ["Task", "Live issue"],
        attachments: [
          {
            id: "jubuK7XGp3",
            name: "mail.jpg",
            src: "/img/others/img-13.jpg",
            size: "36.1kb"
          },
          {
            id: "xsb3HCejCM",
            name: "mail.jpg",
            src: "/img/others/img-14.jpg",
            size: "55.9kb"
          }
        ],
        comments: [
          {
            id: "R22TqMkACm",
            name: "Carolyn Hanson",
            src: "/img/avatars/thumb-9.jpg",
            message:
              "I am baby kitsch plaid mustache, williamsburg butcher gluten-free 3 wolf moon authentic quinoa selvage knausgaard unicorn. Palo santo viral everyday carry, heirloom tumblr raw denim yr iceland wayfarers single-origin coffee tote bag shoreditch cloud bread poke.",
            date: "2024-05-19T18:30:00.000Z"
          }
        ],
        dueDate: "2024-08-04T18:30:00.000Z"
      },
      {
        id: "7qgsduurxt",
        name: "Table data incorrect",
        description:
          "Extra  breve french press organic milk lungo bar. At, carajillo, saucer latte americano and shop rich french press. And barista mazagran, black, cup extraction ristretto plunger pot cinnamon café au lait.",
        cover: "",
        members: [
          {
            id: "8",
            name: "Frederick Adams",
            email: "iamfred@imaze.infotech.io",
            img: "/img/avatars/thumb-8.jpg"
          }
        ],
        labels: ["Bug"],
        attachments: [],
        comments: [
          {
            id: "Wx8FDSsVTg",
            name: "Samantha Phillips",
            src: "/img/avatars/thumb-6.jpg",
            message:
              "Helvetica 8-bit photo booth tumblr food truck. Enamel pin wolf tousled sartorial, brunch shoreditch skateboard beard helvetica. Plaid typewriter gastropub bespoke.",
            date: "2024-05-19T18:30:00.000Z"
          },
          {
            id: "3AhkqqSFFr",
            name: "Ron Vargas",
            src: "/img/avatars/thumb-3.jpg",
            message:
              "Af trust fund meggings, meditation chicharrones brunch wolf narwhal shoreditch whatever bicycle rights ramps. ",
            date: "2024-05-19T18:30:00.000Z"
          }
        ],
        dueDate: "2024-07-10T18:30:00.000Z"
      },
      {
        id: "wtwgpz6csc",
        name: "Fix broken UI",
        description:
          "Air plant subway tile four loko ramps. Microdosing offal tote bag, single-origin coffee biodiesel before they sold out swag pok pok gastropub tacos letterpress.",
        cover: "",
        members: [
          {
            id: "13",
            name: "Lee Wheeler",
            email: "leeWheeler@infotech.io",
            img: "/img/avatars/thumb-13.jpg"
          },
          {
            id: "5",
            name: "Joyce Freeman",
            email: "joyce991@infotech.io",
            img: "/img/avatars/thumb-5.jpg"
          }
        ],
        labels: ["Low priority"],
        attachments: [],
        comments: [
          {
            id: "bAvBdtKHNC",
            name: "Lloyd Obrien",
            src: "/img/avatars/thumb-11.jpg",
            message:
              "Helvetica 8-bit photo booth tumblr food truck. Enamel pin wolf tousled sartorial, brunch shoreditch skateboard beard helvetica. Plaid typewriter gastropub bespoke.",
            date: "2024-05-19T18:30:00.000Z"
          }
        ],
        dueDate: "2024-08-04T18:30:00.000Z"
      }
    ],
    "In Progress": [
      {
        id: "ywejrdr3rn",
        name: "Fix dashboard layout",
        description:
          "Biodiesel selvage letterpress 8-bit, coloring book banjo austin pabst post-ironic try-hard gluten-free tilde authentic prism man braid.",
        cover: "",
        members: [
          {
            id: "2",
            name: "Terrance Moreno",
            email: "",
            img: "/img/avatars/thumb-2.jpg"
          },
          {
            id: "8",
            name: "Frederick Adams",
            email: "iamfred@imaze.infotech.io",
            img: "/img/avatars/thumb-8.jpg"
          }
        ],
        labels: ["Bug"],
        attachments: [],
        comments: [
          {
            id: "afPugkEmkp",
            name: "Carolyn Perkins",
            src: "/img/avatars/thumb-1.jpg",
            message: "",
            date: "2024-06-15T18:30:00.000Z"
          }
        ],
        dueDate: "2024-04-16T18:30:00.000Z"
      },
      {
        id: "tkBXWJGwkr",
        name: "New design",
        description:
          "Typewriter hell of cloud bread health goth beard mlkshk four loko.",
        cover: "",
        members: [
          {
            id: "10",
            name: "Brittany Hale",
            email: "brittany1134@gmail.com",
            img: "/img/avatars/thumb-10.jpg"
          }
        ],
        labels: ["Task"],
        attachments: [
          {
            id: "NjHJhHeWrG",
            name: "issue.jpg",
            src: "/img/others/img-16.jpg",
            size: "46.1kb"
          }
        ],
        comments: [
          {
            id: "MAsPDzGwnA",
            name: "Ron Vargas",
            src: "/img/avatars/thumb-3.jpg",
            message:
              "Af trust fund meggings, meditation chicharrones brunch wolf narwhal shoreditch whatever bicycle rights ramps. ",
            date: "2024-05-19T18:30:00.000Z"
          }
        ],
        dueDate: null
      },
      {
        id: "VQgUDrYJYH",
        name: "Improve user experiences",
        description:
          "Java, barista, single origin, aged foam, id dripper organic at grounds id turkish. Grounds french press viennese, strong dark that extra  spoon café au lait cappuccino. Doppio, coffee, affogato, skinny eu latte, ut carajillo black crema instant dark.",
        cover: "",
        members: [
          {
            id: "6",
            name: "Samantha Phillips",
            email: "samanthaphil@infotech.io",
            img: "/img/avatars/thumb-6.jpg"
          }
        ],
        labels: ["Low priority", "Task"],
        attachments: [],
        comments: [],
        dueDate: "2024-05-19T18:30:00.000Z"
      }
    ],
    Submitted: [
      {
        id: "jzjn95g3v4",
        name: "Update node environment",
        description:
          "Unicorn occupy locavore pug, stumptown literally tofu irony. Af street art paleo shoreditch. Banh mi before they sold out activated charcoal.",
        cover: "",
        members: [
          {
            id: "3",
            name: "Ron Vargas",
            email: "ronnie_vergas@infotech.io",
            img: "/img/avatars/thumb-3.jpg"
          }
        ],
        labels: ["Low priority"],
        attachments: [],
        comments: [
          {
            id: "nBAGhJqe9v",
            name: "Ron Vargas",
            src: "/img/avatars/thumb-3.jpg",
            message: "",
            date: "2024-02-07T18:30:00.000Z"
          }
        ],
        dueDate: null
      },
      {
        id: "ZFQDPmscwA",
        name: "Remove user data",
        description:
          "Crucifix polaroid hot chicken asymmetrical wolf helvetica keytar fashion axe ramps YOLO wayfarers 90s.",
        cover: "/img/others/img-15.jpg",
        members: [
          {
            id: "9",
            name: "Carolyn Hanson",
            email: "carolyn_h@gmail.com",
            img: "/img/avatars/thumb-9.jpg"
          },
          {
            id: "10",
            name: "Brittany Hale",
            email: "brittany1134@gmail.com",
            img: "/img/avatars/thumb-10.jpg"
          }
        ],
        labels: ["Live issue"],
        attachments: [
          {
            id: "NjHJhHeWrG",
            name: "issue.jpg",
            src: "/img/others/img-15.jpg",
            size: "42.6kb"
          }
        ],
        comments: [
          {
            id: "nBAGhJqe9v",
            name: "Ron Vargas",
            src: "/img/avatars/thumb-3.jpg",
            message:
              "Lorem Khaled Ipsum is a major key to success. They key is to have every key, the key to open every door. Im up to something. Life is what you make it, so lets make it.",
            date: "2024-02-07T18:30:00.000Z"
          }
        ],
        dueDate: null
      }
    ],
    Completed: [
      {
        id: "yhjk5679xr",
        name: "Ready to test",
        description:
          "Yr green juice health goth, williamsburg four dollar toast shabby chic meggings jean shorts VHS.",
        cover: "",
        members: [
          {
            id: "13",
            name: "Lee Wheeler",
            email: "leeWheeler@infotech.io",
            img: "/img/avatars/thumb-13.jpg"
          },
          {
            id: "9",
            name: "Carolyn Hanson",
            email: "carolyn_h@gmail.com",
            img: "/img/avatars/thumb-9.jpg"
          }
        ],
        labels: ["Task"],
        attachments: [],
        comments: [
          {
            id: "yxc5gwrXUZ",
            name: "Ron Vargas",
            src: "/img/avatars/thumb-3.jpg",
            message: "",
            date: "2024-04-03T18:30:00.000Z"
          }
        ],
        dueDate: "2024-04-03T18:30:00.000Z"
      },
      {
        id: "UMgvapYVXm",
        name: "Slow API connection",
        description:
          "Yr green juice health goth, williamsburg four dollar toast shabby chic meggings jean shorts VHS.",
        cover: "",
        members: [
          {
            id: "5",
            name: "Joyce Freeman",
            email: "joyce991@infotech.io",
            img: "/img/avatars/thumb-5.jpg"
          },
          {
            id: "6",
            name: "Samantha Phillips",
            email: "samanthaphil@infotech.io",
            img: "/img/avatars/thumb-6.jpg"
          },
          {
            id: "7",
            name: "Tara Fletcher",
            email: "taratarara@imaze.edu.du",
            img: "/img/avatars/thumb-7.jpg"
          }
        ],
        labels: ["Bug"],
        attachments: [],
        comments: [],
        dueDate: "2024-08-18T18:30:00.000Z"
      },
      {
        id: "uRZNVsCmDW",
        name: "Login failed",
        description:
          "Air plant subway tile four loko ramps. Microdosing offal tote bag, single-origin coffee biodiesel before they sold.",
        cover: "",
        members: [
          {
            id: "4",
            name: "Luke Cook",
            email: "cookie_lukie@hotmail.com",
            img: "/img/avatars/thumb-4.jpg"
          }
        ],
        labels: ["Live issue"],
        attachments: [],
        comments: [],
        dueDate: "2024-05-05T18:30:00.000Z"
      },
      {
        id: "PBSGmhVgvS",
        name: "Locale incorrect",
        description:
          "Hoodie mustache woke pour-over you probably havent heard of them cray.",
        cover: "",
        members: [
          {
            id: "5",
            name: "Joyce Freeman",
            email: "joyce991@infotech.io",
            img: "/img/avatars/thumb-5.jpg"
          },
          {
            id: "3",
            name: "Ron Vargas",
            email: "ronnie_vergas@infotech.io",
            img: "/img/avatars/thumb-3.jpg"
          }
        ],
        labels: ["Low priority"],
        attachments: [],
        comments: [
          {
            id: "dNskbPFeQD",
            name: "Ron Vargas",
            src: "/img/avatars/thumb-3.jpg",
            message:
              "Af trust fund meggings, meditation chicharrones brunch wolf narwhal shoreditch whatever bicycle rights ramps. ",
            date: "2024-05-19T18:30:00.000Z"
          },
          {
            id: "qNzkmRZiTO",
            name: "Terrance Moreno",
            src: "/img/avatars/thumb-2.jpg",
            message: "Noted!",
            date: "2024-05-19T18:30:00.000Z"
          }
        ],
        dueDate: "2024-08-12T18:30:00.000Z"
      }
    ]
  };
};

const MembersData = async () => {
  return {
    participantMembers: [
      {
        id: "1",
        name: "Carolyn Perkins",
        email: "eileen_h@hotmail.com",
        img: "/img/avatars/thumb-1.jpg"
      },
      {
        id: "2",
        name: "Terrance Moreno",
        email: "terrance_moreno@infotech.io",
        img: "/img/avatars/thumb-2.jpg"
      },
      {
        id: "3",
        name: "Ron Vargas",
        email: "ronnie_vergas@infotech.io",
        img: "/img/avatars/thumb-3.jpg"
      },
      {
        id: "5",
        name: "Joyce Freeman",
        email: "joyce991@infotech.io",
        img: "/img/avatars/thumb-5.jpg"
      },
      {
        id: "7",
        name: "Tara Fletcher",
        email: "taratarara@imaze.edu.du",
        img: "/img/avatars/thumb-7.jpg"
      },
      {
        id: "9",
        name: "Carolyn Hanson",
        email: "carolyn_h@gmail.com",
        img: "/img/avatars/thumb-9.jpg"
      },
      {
        id: "10",
        name: "Brittany Hale",
        email: "brittany1134@gmail.com",
        img: "/img/avatars/thumb-10.jpg"
      }
    ],
    allMembers: [
      {
        id: "1",
        name: "Carolyn Perkins",
        email: "eileen_h@hotmail.com",
        img: "/img/avatars/thumb-1.jpg"
      },
      {
        id: "2",
        name: "Terrance Moreno",
        email: "terrance_moreno@infotech.io",
        img: "/img/avatars/thumb-2.jpg"
      },
      {
        id: "3",
        name: "Ron Vargas",
        email: "ronnie_vergas@infotech.io",
        img: "/img/avatars/thumb-3.jpg"
      },
      {
        id: "4",
        name: "Luke Cook",
        email: "cookie_lukie@hotmail.com",
        img: "/img/avatars/thumb-4.jpg"
      },
      {
        id: "5",
        name: "Joyce Freeman",
        email: "joyce991@infotech.io",
        img: "/img/avatars/thumb-5.jpg"
      },
      {
        id: "6",
        name: "Samantha Phillips",
        email: "samanthaphil@infotech.io",
        img: "/img/avatars/thumb-6.jpg"
      },
      {
        id: "7",
        name: "Tara Fletcher",
        email: "taratarara@imaze.edu.du",
        img: "/img/avatars/thumb-7.jpg"
      },
      {
        id: "8",
        name: "Frederick Adams",
        email: "iamfred@imaze.infotech.io",
        img: "/img/avatars/thumb-8.jpg"
      },
      {
        id: "9",
        name: "Carolyn Hanson",
        email: "carolyn_h@gmail.com",
        img: "/img/avatars/thumb-9.jpg"
      },
      {
        id: "10",
        name: "Brittany Hale",
        email: "brittany1134@gmail.com",
        img: "/img/avatars/thumb-10.jpg"
      },
      {
        id: "11",
        name: "Lloyd Obrien",
        email: "handsome-obrien@hotmail.com",
        img: "/img/avatars/thumb-11.jpg"
      },
      {
        id: "12",
        name: "Gabriella May",
        email: "maymaymay12@infotech.io",
        img: "/img/avatars/thumb-12.jpg"
      },
      {
        id: "13",
        name: "Lee Wheeler",
        email: "lee_wheeler@infotech.io",
        img: "/img/avatars/thumb-13.jpg"
      },
      {
        id: "14",
        name: "Gail Barnes",
        email: "gailby0116@infotech.io",
        img: "/img/avatars/thumb-14.jpg"
      },
      {
        id: "15",
        name: "Ella Robinson",
        email: "ella_robinson@infotech.io",
        img: "/img/avatars/thumb-15.jpg"
      }
    ]
  };
};
export const getBoards = createAsyncThunk("scrumBoard/getBoards", async () => {
  const response = await BoardsData();
  return response.data;
});
export const getMembers = createAsyncThunk(
  "scrumBoard/getMembers",
  async () => {
    const response = await MembersData();
    return response.data;
  }
);
const dataSlice = createSlice({
  name: "scrumBoard",
  initialState: {
    loading: false,
    columns: {
      "To Do": [
        {
          id: "zb7zxtjctd",
          name: "Unable to upload file 123",
          description:
            "Scenester hashtag sustainable art party 3 wolf moon live-edge portland offal master cleanse disrupt viral palo santo tacos. Offal sriracha you probably havent heard of them vaporware glossier.",
          cover: "/img/others/img-13.jpg",
          members: [
            {
              id: "1",
              name: "Carolyn Perkins",
              email: "eileen_h@hotmail.com",
              img: "/img/avatars/thumb-1.jpg"
            },
            {
              id: "2",
              name: "Terrance Moreno",
              email: "",
              img: "/img/avatars/thumb-2.jpg"
            },
            {
              id: "3",
              name: "Ron Vargas",
              email: "ronnie_vergas@infotech.io",
              img: "/img/avatars/thumb-3.jpg"
            }
          ],
          labels: ["Task", "Live issue"],
          attachments: [
            {
              id: "jubuK7XGp3",
              name: "mail.jpg",
              src: "/img/others/img-13.jpg",
              size: "36.1kb"
            },
            {
              id: "xsb3HCejCM",
              name: "mail.jpg",
              src: "/img/others/img-14.jpg",
              size: "55.9kb"
            }
          ],
          comments: [
            {
              id: "R22TqMkACm",
              name: "Carolyn Hanson",
              src: "/img/avatars/thumb-9.jpg",
              message:
                "I am baby kitsch plaid mustache, williamsburg butcher gluten-free 3 wolf moon authentic quinoa selvage knausgaard unicorn. Palo santo viral everyday carry, heirloom tumblr raw denim yr iceland wayfarers single-origin coffee tote bag shoreditch cloud bread poke.",
              date: "2024-05-19T18:30:00.000Z"
            }
          ],
          dueDate: "2024-08-04T18:30:00.000Z"
        },
        {
          id: "7qgsduurxt",
          name: "Table data incorrect",
          description:
            "Extra  breve french press organic milk lungo bar. At, carajillo, saucer latte americano and shop rich french press. And barista mazagran, black, cup extraction ristretto plunger pot cinnamon café au lait.",
          cover: "",
          members: [
            {
              id: "8",
              name: "Frederick Adams",
              email: "iamfred@imaze.infotech.io",
              img: "/img/avatars/thumb-8.jpg"
            }
          ],
          labels: ["Bug"],
          attachments: [],
          comments: [
            {
              id: "Wx8FDSsVTg",
              name: "Samantha Phillips",
              src: "/img/avatars/thumb-6.jpg",
              message:
                "Helvetica 8-bit photo booth tumblr food truck. Enamel pin wolf tousled sartorial, brunch shoreditch skateboard beard helvetica. Plaid typewriter gastropub bespoke.",
              date: "2024-05-19T18:30:00.000Z"
            },
            {
              id: "3AhkqqSFFr",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message:
                "Af trust fund meggings, meditation chicharrones brunch wolf narwhal shoreditch whatever bicycle rights ramps. ",
              date: "2024-05-19T18:30:00.000Z"
            }
          ],
          dueDate: "2024-07-10T18:30:00.000Z"
        },
        {
          id: "wtwgpz6csc",
          name: "Fix broken UI",
          description:
            "Air plant subway tile four loko ramps. Microdosing offal tote bag, single-origin coffee biodiesel before they sold out swag pok pok gastropub tacos letterpress.",
          cover: "",
          members: [
            {
              id: "13",
              name: "Lee Wheeler",
              email: "leeWheeler@infotech.io",
              img: "/img/avatars/thumb-13.jpg"
            },
            {
              id: "5",
              name: "Joyce Freeman",
              email: "joyce991@infotech.io",
              img: "/img/avatars/thumb-5.jpg"
            }
          ],
          labels: ["Low priority"],
          attachments: [],
          comments: [
            {
              id: "bAvBdtKHNC",
              name: "Lloyd Obrien",
              src: "/img/avatars/thumb-11.jpg",
              message:
                "Helvetica 8-bit photo booth tumblr food truck. Enamel pin wolf tousled sartorial, brunch shoreditch skateboard beard helvetica. Plaid typewriter gastropub bespoke.",
              date: "2024-05-19T18:30:00.000Z"
            }
          ],
          dueDate: "2024-08-04T18:30:00.000Z"
        }
      ],
      "In Progress": [
        {
          id: "ywejrdr3rn",
          name: "Fix dashboard layout",
          description:
            "Biodiesel selvage letterpress 8-bit, coloring book banjo austin pabst post-ironic try-hard gluten-free tilde authentic prism man braid.",
          cover: "",
          members: [
            {
              id: "2",
              name: "Terrance Moreno",
              email: "",
              img: "/img/avatars/thumb-2.jpg"
            },
            {
              id: "8",
              name: "Frederick Adams",
              email: "iamfred@imaze.infotech.io",
              img: "/img/avatars/thumb-8.jpg"
            }
          ],
          labels: ["Bug"],
          attachments: [],
          comments: [
            {
              id: "afPugkEmkp",
              name: "Carolyn Perkins",
              src: "/img/avatars/thumb-1.jpg",
              message: "",
              date: "2024-06-15T18:30:00.000Z"
            }
          ],
          dueDate: "2024-04-16T18:30:00.000Z"
        },
        {
          id: "tkBXWJGwkr",
          name: "New design",
          description:
            "Typewriter hell of cloud bread health goth beard mlkshk four loko.",
          cover: "",
          members: [
            {
              id: "10",
              name: "Brittany Hale",
              email: "brittany1134@gmail.com",
              img: "/img/avatars/thumb-10.jpg"
            }
          ],
          labels: ["Task"],
          attachments: [
            {
              id: "NjHJhHeWrG",
              name: "issue.jpg",
              src: "/img/others/img-16.jpg",
              size: "46.1kb"
            }
          ],
          comments: [
            {
              id: "MAsPDzGwnA",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message:
                "Af trust fund meggings, meditation chicharrones brunch wolf narwhal shoreditch whatever bicycle rights ramps. ",
              date: "2024-05-19T18:30:00.000Z"
            }
          ],
          dueDate: null
        },
        {
          id: "VQgUDrYJYH",
          name: "Improve user experiences",
          description:
            "Java, barista, single origin, aged foam, id dripper organic at grounds id turkish. Grounds french press viennese, strong dark that extra  spoon café au lait cappuccino. Doppio, coffee, affogato, skinny eu latte, ut carajillo black crema instant dark.",
          cover: "",
          members: [
            {
              id: "6",
              name: "Samantha Phillips",
              email: "samanthaphil@infotech.io",
              img: "/img/avatars/thumb-6.jpg"
            }
          ],
          labels: ["Low priority", "Task"],
          attachments: [],
          comments: [],
          dueDate: "2024-05-19T18:30:00.000Z"
        }
      ],
      Submitted: [
        {
          id: "jzjn95g3v4",
          name: "Update node environment",
          description:
            "Unicorn occupy locavore pug, stumptown literally tofu irony. Af street art paleo shoreditch. Banh mi before they sold out activated charcoal.",
          cover: "",
          members: [
            {
              id: "3",
              name: "Ron Vargas",
              email: "ronnie_vergas@infotech.io",
              img: "/img/avatars/thumb-3.jpg"
            }
          ],
          labels: ["Low priority"],
          attachments: [],
          comments: [
            {
              id: "nBAGhJqe9v",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message: "",
              date: "2024-02-07T18:30:00.000Z"
            }
          ],
          dueDate: null
        },
        {
          id: "ZFQDPmscwA",
          name: "Remove user data",
          description:
            "Crucifix polaroid hot chicken asymmetrical wolf helvetica keytar fashion axe ramps YOLO wayfarers 90s.",
          cover: "/img/others/img-15.jpg",
          members: [
            {
              id: "9",
              name: "Carolyn Hanson",
              email: "carolyn_h@gmail.com",
              img: "/img/avatars/thumb-9.jpg"
            },
            {
              id: "10",
              name: "Brittany Hale",
              email: "brittany1134@gmail.com",
              img: "/img/avatars/thumb-10.jpg"
            }
          ],
          labels: ["Live issue"],
          attachments: [
            {
              id: "NjHJhHeWrG",
              name: "issue.jpg",
              src: "/img/others/img-15.jpg",
              size: "42.6kb"
            }
          ],
          comments: [
            {
              id: "nBAGhJqe9v",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message:
                "Lorem Khaled Ipsum is a major key to success. They key is to have every key, the key to open every door. Im up to something. Life is what you make it, so lets make it.",
              date: "2024-02-07T18:30:00.000Z"
            }
          ],
          dueDate: null
        }
      ],
      Completed: [
        {
          id: "yhjk5679xr",
          name: "Ready to test",
          description:
            "Yr green juice health goth, williamsburg four dollar toast shabby chic meggings jean shorts VHS.",
          cover: "",
          members: [
            {
              id: "13",
              name: "Lee Wheeler",
              email: "leeWheeler@infotech.io",
              img: "/img/avatars/thumb-13.jpg"
            },
            {
              id: "9",
              name: "Carolyn Hanson",
              email: "carolyn_h@gmail.com",
              img: "/img/avatars/thumb-9.jpg"
            }
          ],
          labels: ["Task"],
          attachments: [],
          comments: [
            {
              id: "yxc5gwrXUZ",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message: "",
              date: "2024-04-03T18:30:00.000Z"
            }
          ],
          dueDate: "2024-04-03T18:30:00.000Z"
        },
        {
          id: "UMgvapYVXm",
          name: "Slow API connection",
          description:
            "Yr green juice health goth, williamsburg four dollar toast shabby chic meggings jean shorts VHS.",
          cover: "",
          members: [
            {
              id: "5",
              name: "Joyce Freeman",
              email: "joyce991@infotech.io",
              img: "/img/avatars/thumb-5.jpg"
            },
            {
              id: "6",
              name: "Samantha Phillips",
              email: "samanthaphil@infotech.io",
              img: "/img/avatars/thumb-6.jpg"
            },
            {
              id: "7",
              name: "Tara Fletcher",
              email: "taratarara@imaze.edu.du",
              img: "/img/avatars/thumb-7.jpg"
            }
          ],
          labels: ["Bug"],
          attachments: [],
          comments: [],
          dueDate: "2024-08-18T18:30:00.000Z"
        },
        {
          id: "uRZNVsCmDW",
          name: "Login failed",
          description:
            "Air plant subway tile four loko ramps. Microdosing offal tote bag, single-origin coffee biodiesel before they sold.",
          cover: "",
          members: [
            {
              id: "4",
              name: "Luke Cook",
              email: "cookie_lukie@hotmail.com",
              img: "/img/avatars/thumb-4.jpg"
            }
          ],
          labels: ["Live issue"],
          attachments: [],
          comments: [],
          dueDate: "2024-05-05T18:30:00.000Z"
        },
        {
          id: "PBSGmhVgvS",
          name: "Locale incorrect",
          description:
            "Hoodie mustache woke pour-over you probably havent heard of them cray.",
          cover: "",
          members: [
            {
              id: "5",
              name: "Joyce Freeman",
              email: "joyce991@infotech.io",
              img: "/img/avatars/thumb-5.jpg"
            },
            {
              id: "3",
              name: "Ron Vargas",
              email: "ronnie_vergas@infotech.io",
              img: "/img/avatars/thumb-3.jpg"
            }
          ],
          labels: ["Low priority"],
          attachments: [],
          comments: [
            {
              id: "dNskbPFeQD",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message:
                "Af trust fund meggings, meditation chicharrones brunch wolf narwhal shoreditch whatever bicycle rights ramps. ",
              date: "2024-05-19T18:30:00.000Z"
            },
            {
              id: "qNzkmRZiTO",
              name: "Terrance Moreno",
              src: "/img/avatars/thumb-2.jpg",
              message: "Noted!",
              date: "2024-05-19T18:30:00.000Z"
            }
          ],
          dueDate: "2024-08-12T18:30:00.000Z"
        }
      ]
    },
    ordered: ["To Do",  "Submitted","In Progress", "Completed"],
    boardMembers: [
      {
        id: "1",
        name: "Carolyn Perkins",
        email: "eileen_h@hotmail.com",
        img: "/img/avatars/thumb-1.jpg"
      },
      {
        id: "2",
        name: "Terrance Moreno",
        email: "terrance_moreno@infotech.io",
        img: "/img/avatars/thumb-2.jpg"
      },
      {
        id: "3",
        name: "Ron Vargas",
        email: "ronnie_vergas@infotech.io",
        img: "/img/avatars/thumb-3.jpg"
      },
      {
        id: "5",
        name: "Joyce Freeman",
        email: "joyce991@infotech.io",
        img: "/img/avatars/thumb-5.jpg"
      },
      {
        id: "7",
        name: "Tara Fletcher",
        email: "taratarara@imaze.edu.du",
        img: "/img/avatars/thumb-7.jpg"
      },
      {
        id: "9",
        name: "Carolyn Hanson",
        email: "carolyn_h@gmail.com",
        img: "/img/avatars/thumb-9.jpg"
      },
      {
        id: "10",
        name: "Brittany Hale",
        email: "brittany1134@gmail.com",
        img: "/img/avatars/thumb-10.jpg"
      }
    ],
    allMembers: [
      {
        id: "1",
        name: "Carolyn Perkins",
        email: "eileen_h@hotmail.com",
        img: "/img/avatars/thumb-1.jpg"
      },
      {
        id: "2",
        name: "Terrance Moreno",
        email: "terrance_moreno@infotech.io",
        img: "/img/avatars/thumb-2.jpg"
      },
      {
        id: "3",
        name: "Ron Vargas",
        email: "ronnie_vergas@infotech.io",
        img: "/img/avatars/thumb-3.jpg"
      },
      {
        id: "4",
        name: "Luke Cook",
        email: "cookie_lukie@hotmail.com",
        img: "/img/avatars/thumb-4.jpg"
      },
      {
        id: "5",
        name: "Joyce Freeman",
        email: "joyce991@infotech.io",
        img: "/img/avatars/thumb-5.jpg"
      },
      {
        id: "6",
        name: "Samantha Phillips",
        email: "samanthaphil@infotech.io",
        img: "/img/avatars/thumb-6.jpg"
      },
      {
        id: "7",
        name: "Tara Fletcher",
        email: "taratarara@imaze.edu.du",
        img: "/img/avatars/thumb-7.jpg"
      },
      {
        id: "8",
        name: "Frederick Adams",
        email: "iamfred@imaze.infotech.io",
        img: "/img/avatars/thumb-8.jpg"
      },
      {
        id: "9",
        name: "Carolyn Hanson",
        email: "carolyn_h@gmail.com",
        img: "/img/avatars/thumb-9.jpg"
      },
      {
        id: "10",
        name: "Brittany Hale",
        email: "brittany1134@gmail.com",
        img: "/img/avatars/thumb-10.jpg"
      },
      {
        id: "11",
        name: "Lloyd Obrien",
        email: "handsome-obrien@hotmail.com",
        img: "/img/avatars/thumb-11.jpg"
      },
      {
        id: "12",
        name: "Gabriella May",
        email: "maymaymay12@infotech.io",
        img: "/img/avatars/thumb-12.jpg"
      },
      {
        id: "13",
        name: "Lee Wheeler",
        email: "lee_wheeler@infotech.io",
        img: "/img/avatars/thumb-13.jpg"
      },
      {
        id: "14",
        name: "Gail Barnes",
        email: "gailby0116@infotech.io",
        img: "/img/avatars/thumb-14.jpg"
      },
      {
        id: "15",
        name: "Ella Robinson",
        email: "ella_robinson@infotech.io",
        img: "/img/avatars/thumb-15.jpg"
      }
    ]
  },
  reducers: {
    updateOrdered: (state, action) => {
      state.ordered = action.payload;
    },
    updateColumns: (state, action) => {
      state.columns = action.payload;
    },
    updateBoardMembers: (state, action) => {
      state.boardMembers = action.payload;
    }
  },
  
});
export const randomData = {
  data: {
    loading: false,
    columns: {
      "To Do": [
        {
          id: "zb7zxtjctd",
          name: "Unable to upload file12345",
          description:
            "Scenester hashtag sustainable art party 3 wolf moon live-edge portland offal master cleanse disrupt viral palo santo tacos. Offal sriracha you probably havent heard of them vaporware glossier.",
          cover: "/img/others/img-13.jpg",
          members: [
            {
              id: "1",
              name: "Carolyn Perkins",
              email: "eileen_h@hotmail.com",
              img: "/img/avatars/thumb-1.jpg"
            },
            {
              id: "2",
              name: "Terrance Moreno",
              email: "",
              img: "/img/avatars/thumb-2.jpg"
            },
            {
              id: "3",
              name: "Ron Vargas",
              email: "ronnie_vergas@infotech.io",
              img: "/img/avatars/thumb-3.jpg"
            }
          ],
          labels: ["Task", "Live issue"],
          attachments: [
            {
              id: "jubuK7XGp3",
              name: "mail.jpg",
              src: "/img/others/img-13.jpg",
              size: "36.1kb"
            },
            {
              id: "xsb3HCejCM",
              name: "mail.jpg",
              src: "/img/others/img-14.jpg",
              size: "55.9kb"
            }
          ],
          comments: [
            {
              id: "R22TqMkACm",
              name: "Carolyn Hanson",
              src: "/img/avatars/thumb-9.jpg",
              message:
                "I am baby kitsch plaid mustache, williamsburg butcher gluten-free 3 wolf moon authentic quinoa selvage knausgaard unicorn. Palo santo viral everyday carry, heirloom tumblr raw denim yr iceland wayfarers single-origin coffee tote bag shoreditch cloud bread poke.",
              date: "2024-05-19T18:30:00.000Z"
            }
          ],
          dueDate: "2024-08-04T18:30:00.000Z"
        },
        {
          id: "7qgsduurxt",
          name: "Table data incorrect",
          description:
            "Extra  breve french press organic milk lungo bar. At, carajillo, saucer latte americano and shop rich french press. And barista mazagran, black, cup extraction ristretto plunger pot cinnamon café au lait.",
          cover: "",
          members: [
            {
              id: "8",
              name: "Frederick Adams",
              email: "iamfred@imaze.infotech.io",
              img: "/img/avatars/thumb-8.jpg"
            }
          ],
          labels: ["Bug"],
          attachments: [],
          comments: [
            {
              id: "Wx8FDSsVTg",
              name: "Samantha Phillips",
              src: "/img/avatars/thumb-6.jpg",
              message:
                "Helvetica 8-bit photo booth tumblr food truck. Enamel pin wolf tousled sartorial, brunch shoreditch skateboard beard helvetica. Plaid typewriter gastropub bespoke.",
              date: "2024-05-19T18:30:00.000Z"
            },
            {
              id: "3AhkqqSFFr",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message:
                "Af trust fund meggings, meditation chicharrones brunch wolf narwhal shoreditch whatever bicycle rights ramps. ",
              date: "2024-05-19T18:30:00.000Z"
            }
          ],
          dueDate: "2024-07-10T18:30:00.000Z"
        },
        {
          id: "wtwgpz6csc",
          name: "Fix broken UI",
          description:
            "Air plant subway tile four loko ramps. Microdosing offal tote bag, single-origin coffee biodiesel before they sold out swag pok pok gastropub tacos letterpress.",
          cover: "",
          members: [
            {
              id: "13",
              name: "Lee Wheeler",
              email: "leeWheeler@infotech.io",
              img: "/img/avatars/thumb-13.jpg"
            },
            {
              id: "5",
              name: "Joyce Freeman",
              email: "joyce991@infotech.io",
              img: "/img/avatars/thumb-5.jpg"
            }
          ],
          labels: ["Low priority"],
          attachments: [],
          comments: [
            {
              id: "bAvBdtKHNC",
              name: "Lloyd Obrien",
              src: "/img/avatars/thumb-11.jpg",
              message:
                "Helvetica 8-bit photo booth tumblr food truck. Enamel pin wolf tousled sartorial, brunch shoreditch skateboard beard helvetica. Plaid typewriter gastropub bespoke.",
              date: "2024-05-19T18:30:00.000Z"
            }
          ],
          dueDate: "2024-08-04T18:30:00.000Z"
        }
      ],
      "In Progress": [
        {
          id: "ywejrdr3rn",
          name: "Fix dashboard layout",
          description:
            "Biodiesel selvage letterpress 8-bit, coloring book banjo austin pabst post-ironic try-hard gluten-free tilde authentic prism man braid.",
          cover: "",
          members: [
            {
              id: "2",
              name: "Terrance Moreno",
              email: "",
              img: "/img/avatars/thumb-2.jpg"
            },
            {
              id: "8",
              name: "Frederick Adams",
              email: "iamfred@imaze.infotech.io",
              img: "/img/avatars/thumb-8.jpg"
            }
          ],
          labels: ["Bug"],
          attachments: [],
          comments: [
            {
              id: "afPugkEmkp",
              name: "Carolyn Perkins",
              src: "/img/avatars/thumb-1.jpg",
              message: "",
              date: "2024-06-15T18:30:00.000Z"
            }
          ],
          dueDate: "2024-04-16T18:30:00.000Z"
        },
        {
          id: "tkBXWJGwkr",
          name: "New design",
          description:
            "Typewriter hell of cloud bread health goth beard mlkshk four loko.",
          cover: "",
          members: [
            {
              id: "10",
              name: "Brittany Hale",
              email: "brittany1134@gmail.com",
              img: "/img/avatars/thumb-10.jpg"
            }
          ],
          labels: ["Task"],
          attachments: [
            {
              id: "NjHJhHeWrG",
              name: "issue.jpg",
              src: "/img/others/img-16.jpg",
              size: "46.1kb"
            }
          ],
          comments: [
            {
              id: "MAsPDzGwnA",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message:
                "Af trust fund meggings, meditation chicharrones brunch wolf narwhal shoreditch whatever bicycle rights ramps. ",
              date: "2024-05-19T18:30:00.000Z"
            }
          ],
          dueDate: null
        },
        {
          id: "VQgUDrYJYH",
          name: "Improve user experiences",
          description:
            "Java, barista, single origin, aged foam, id dripper organic at grounds id turkish. Grounds french press viennese, strong dark that extra  spoon café au lait cappuccino. Doppio, coffee, affogato, skinny eu latte, ut carajillo black crema instant dark.",
          cover: "",
          members: [
            {
              id: "6",
              name: "Samantha Phillips",
              email: "samanthaphil@infotech.io",
              img: "/img/avatars/thumb-6.jpg"
            }
          ],
          labels: ["Low priority", "Task"],
          attachments: [],
          comments: [],
          dueDate: "2024-05-19T18:30:00.000Z"
        }
      ],
      Submitted: [
        {
          id: "jzjn95g3v4",
          name: "Update node environment",
          description:
            "Unicorn occupy locavore pug, stumptown literally tofu irony. Af street art paleo shoreditch. Banh mi before they sold out activated charcoal.",
          cover: "",
          members: [
            {
              id: "3",
              name: "Ron Vargas",
              email: "ronnie_vergas@infotech.io",
              img: "/img/avatars/thumb-3.jpg"
            }
          ],
          labels: ["Low priority"],
          attachments: [],
          comments: [
            {
              id: "nBAGhJqe9v",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message: "",
              date: "2024-02-07T18:30:00.000Z"
            }
          ],
          dueDate: null
        },
        {
          id: "ZFQDPmscwA",
          name: "Remove user data",
          description:
            "Crucifix polaroid hot chicken asymmetrical wolf helvetica keytar fashion axe ramps YOLO wayfarers 90s.",
          cover: "/img/others/img-15.jpg",
          members: [
            {
              id: "9",
              name: "Carolyn Hanson",
              email: "carolyn_h@gmail.com",
              img: "/img/avatars/thumb-9.jpg"
            },
            {
              id: "10",
              name: "Brittany Hale",
              email: "brittany1134@gmail.com",
              img: "/img/avatars/thumb-10.jpg"
            }
          ],
          labels: ["Live issue"],
          attachments: [
            {
              id: "NjHJhHeWrG",
              name: "issue.jpg",
              src: "/img/others/img-15.jpg",
              size: "42.6kb"
            }
          ],
          comments: [
            {
              id: "nBAGhJqe9v",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message:
                "Lorem Khaled Ipsum is a major key to success. They key is to have every key, the key to open every door. Im up to something. Life is what you make it, so lets make it.",
              date: "2024-02-07T18:30:00.000Z"
            }
          ],
          dueDate: null
        }
      ],
      Completed: [
        {
          id: "yhjk5679xr",
          name: "Ready to test",
          description:
            "Yr green juice health goth, williamsburg four dollar toast shabby chic meggings jean shorts VHS.",
          cover: "",
          members: [
            {
              id: "13",
              name: "Lee Wheeler",
              email: "leeWheeler@infotech.io",
              img: "/img/avatars/thumb-13.jpg"
            },
            {
              id: "9",
              name: "Carolyn Hanson",
              email: "carolyn_h@gmail.com",
              img: "/img/avatars/thumb-9.jpg"
            }
          ],
          labels: ["Task"],
          attachments: [],
          comments: [
            {
              id: "yxc5gwrXUZ",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message: "",
              date: "2024-04-03T18:30:00.000Z"
            }
          ],
          dueDate: "2024-04-03T18:30:00.000Z"
        },
        {
          id: "UMgvapYVXm",
          name: "Slow API connection",
          description:
            "Yr green juice health goth, williamsburg four dollar toast shabby chic meggings jean shorts VHS.",
          cover: "",
          members: [
            {
              id: "5",
              name: "Joyce Freeman",
              email: "joyce991@infotech.io",
              img: "/img/avatars/thumb-5.jpg"
            },
            {
              id: "6",
              name: "Samantha Phillips",
              email: "samanthaphil@infotech.io",
              img: "/img/avatars/thumb-6.jpg"
            },
            {
              id: "7",
              name: "Tara Fletcher",
              email: "taratarara@imaze.edu.du",
              img: "/img/avatars/thumb-7.jpg"
            }
          ],
          labels: ["Bug"],
          attachments: [],
          comments: [],
          dueDate: "2024-08-18T18:30:00.000Z"
        },
        {
          id: "uRZNVsCmDW",
          name: "Login failed",
          description:
            "Air plant subway tile four loko ramps. Microdosing offal tote bag, single-origin coffee biodiesel before they sold.",
          cover: "",
          members: [
            {
              id: "4",
              name: "Luke Cook",
              email: "cookie_lukie@hotmail.com",
              img: "/img/avatars/thumb-4.jpg"
            }
          ],
          labels: ["Live issue"],
          attachments: [],
          comments: [],
          dueDate: "2024-05-05T18:30:00.000Z"
        },
        {
          id: "PBSGmhVgvS",
          name: "Locale incorrect",
          description:
            "Hoodie mustache woke pour-over you probably havent heard of them cray.",
          cover: "",
          members: [
            {
              id: "5",
              name: "Joyce Freeman",
              email: "joyce991@infotech.io",
              img: "/img/avatars/thumb-5.jpg"
            },
            {
              id: "3",
              name: "Ron Vargas",
              email: "ronnie_vergas@infotech.io",
              img: "/img/avatars/thumb-3.jpg"
            }
          ],
          labels: ["Low priority"],
          attachments: [],
          comments: [
            {
              id: "dNskbPFeQD",
              name: "Ron Vargas",
              src: "/img/avatars/thumb-3.jpg",
              message:
                "Af trust fund meggings, meditation chicharrones brunch wolf narwhal shoreditch whatever bicycle rights ramps. ",
              date: "2024-05-19T18:30:00.000Z"
            },
            {
              id: "qNzkmRZiTO",
              name: "Terrance Moreno",
              src: "/img/avatars/thumb-2.jpg",
              message: "Noted!",
              date: "2024-05-19T18:30:00.000Z"
            }
          ],
          dueDate: "2024-08-12T18:30:00.000Z"
        }
      ]
    },
    ordered: ["To Do", "In Progress", "Submitted", "Completed"],
    boardMembers: [
      {
        id: "1",
        name: "Carolyn Perkins",
        email: "eileen_h@hotmail.com",
        img: "/img/avatars/thumb-1.jpg"
      },
      {
        id: "2",
        name: "Terrance Moreno",
        email: "terrance_moreno@infotech.io",
        img: "/img/avatars/thumb-2.jpg"
      },
      {
        id: "3",
        name: "Ron Vargas",
        email: "ronnie_vergas@infotech.io",
        img: "/img/avatars/thumb-3.jpg"
      },
      {
        id: "5",
        name: "Joyce Freeman",
        email: "joyce991@infotech.io",
        img: "/img/avatars/thumb-5.jpg"
      },
      {
        id: "7",
        name: "Tara Fletcher",
        email: "taratarara@imaze.edu.du",
        img: "/img/avatars/thumb-7.jpg"
      },
      {
        id: "9",
        name: "Carolyn Hanson",
        email: "carolyn_h@gmail.com",
        img: "/img/avatars/thumb-9.jpg"
      },
      {
        id: "10",
        name: "Brittany Hale",
        email: "brittany1134@gmail.com",
        img: "/img/avatars/thumb-10.jpg"
      }
    ],
    allMembers: [
      {
        id: "1",
        name: "Carolyn Perkins",
        email: "eileen_h@hotmail.com",
        img: "/img/avatars/thumb-1.jpg"
      },
      {
        id: "2",
        name: "Terrance Moreno",
        email: "terrance_moreno@infotech.io",
        img: "/img/avatars/thumb-2.jpg"
      },
      {
        id: "3",
        name: "Ron Vargas",
        email: "ronnie_vergas@infotech.io",
        img: "/img/avatars/thumb-3.jpg"
      },
      {
        id: "4",
        name: "Luke Cook",
        email: "cookie_lukie@hotmail.com",
        img: "/img/avatars/thumb-4.jpg"
      },
      {
        id: "5",
        name: "Joyce Freeman",
        email: "joyce991@infotech.io",
        img: "/img/avatars/thumb-5.jpg"
      },
      {
        id: "6",
        name: "Samantha Phillips",
        email: "samanthaphil@infotech.io",
        img: "/img/avatars/thumb-6.jpg"
      },
      {
        id: "7",
        name: "Tara Fletcher",
        email: "taratarara@imaze.edu.du",
        img: "/img/avatars/thumb-7.jpg"
      },
      {
        id: "8",
        name: "Frederick Adams",
        email: "iamfred@imaze.infotech.io",
        img: "/img/avatars/thumb-8.jpg"
      },
      {
        id: "9",
        name: "Carolyn Hanson",
        email: "carolyn_h@gmail.com",
        img: "/img/avatars/thumb-9.jpg"
      },
      {
        id: "10",
        name: "Brittany Hale",
        email: "brittany1134@gmail.com",
        img: "/img/avatars/thumb-10.jpg"
      },
      {
        id: "11",
        name: "Lloyd Obrien",
        email: "handsome-obrien@hotmail.com",
        img: "/img/avatars/thumb-11.jpg"
      },
      {
        id: "12",
        name: "Gabriella May",
        email: "maymaymay12@infotech.io",
        img: "/img/avatars/thumb-12.jpg"
      },
      {
        id: "13",
        name: "Lee Wheeler",
        email: "lee_wheeler@infotech.io",
        img: "/img/avatars/thumb-13.jpg"
      },
      {
        id: "14",
        name: "Gail Barnes",
        email: "gailby0116@infotech.io",
        img: "/img/avatars/thumb-14.jpg"
      },
      {
        id: "15",
        name: "Ella Robinson",
        email: "ella_robinson@infotech.io",
        img: "/img/avatars/thumb-15.jpg"
      }
    ]
  },
  state: {
    dialogOpen: false,
    dialogView: "",
    ticketId: "",
    board: "",
    selectedTab: "All"
  }
};
export const { updateOrdered, updateColumns, updateBoardMembers } =
  dataSlice.actions;

export default dataSlice.reducer;
