import api from "service/api";

export function login(data) {
  return api.post("/auth/dashboard-login", data);
}

export function forgotPassword(data) {
  return api.post(`/auth/forgotPassword`, data);
}

export function verifyOTP(data) {
  return api.post(`/auth/verifyOtp`, data);
}
export function emailVerificationCode(data) {
  return api.post(`/auth/email-verification-code`, data);
}
export function emailVerificationOtp(data) {
  return api.post(`/auth/email-verification-otp`, data);
}

export function resetPassword(data) {
  return api.post(`/auth/resetPassword`, data);
}

// add system token
export const addSystemToken = (data) => {
  return api.post(`/notification-token/add-token`, data);
};
