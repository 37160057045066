import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const sprintsSlice = createSlice({
  name: "sprints",
  initialState,
  reducers: {
    setSprintData: (state, action) => {
      state.setSprintData = action.payload;
    },
    setSprintProjectDetails: (state, action) => {
      state.setSprintProjectDetails = action.payload;
    },
    setSprintAssignEmployee: (state, action) => {
      state.setSprintAssignEmployee = action.payload;
    },
    setAllEmployee: (state, action) => {
      state.setAllEmployee = action.payload;
    },
    setSprintBoards: (state, action) => {
      state.setSprintBoards = action.payload;
    },
    setBoardTaskList: (state, action) => {
      state.setBoardTaskList = action.payload;
    },
    setSelectedBoardItem: (state, action) => {
      state.setSelectedBoardItem = action.payload;
    },
    setAllTags: (state, action) => {
      state.setAllTags = action.payload;
    }
  }
});

export const {
  setSprintData,
  setSprintProjectDetails,setAllTags,
  setSprintAssignEmployee,setSelectedBoardItem,
  setAllEmployee,setSprintBoards,setBoardTaskList
} = sprintsSlice.actions;

export default sprintsSlice.reducer;
