import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "scrumBoard",
  initialState: {
    dialogOpen: false,
    dialogView: "",
    ticketId: "",
    subTicketIndex: -1,
    board: "",
    selectedTab: "board",
    meMode: false,
    selectedTags: [],
    selectedUsers: []
  },
  reducers: {
    openDialog: (state) => {
      state.dialogOpen = true;
    },
    closeDialog: (state) => {
      state.dialogOpen = false;
      state.ticketId = "";
      state.board = "";
      state.dialogView = "";
    },
    updateDialogView: (state, action) => {
      state.dialogView = action.payload;
    },
    setSelectedTicketId: (state, action) => {
      state.ticketId = action.payload;
    },
    setSelectedSubTicketIndex: (state, action) => {
      state.subTicketIndex = action.payload;
    },
    setSelectedBoard: (state, action) => {
      state.board = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setMeMode: (state, action) => {
      state.meMode = action.payload;
    },
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    }
  }
});

export const {
  openDialog,
  updateDialogView,
  closeDialog,
  setSelectedTicketId,
  setSelectedBoard,
  setSelectedTab,
  setSelectedSubTicketIndex,
  setMeMode,
  setSelectedUsers,
  setSelectedTags
} = stateSlice.actions;

export default stateSlice.reducer;
